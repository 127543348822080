import request from '@/utils/request'

//创建一个权限
export function createPermission(data){
    return request({
        url: Config.url203+'/create',
        method: 'post',
        data:data
    })
}

//修改权限
export function upDatePermission(data){
    return request({
        url:Config.url203+'/update',
        method:'post',
        data:data
    })
}

//查询权限
export function queryPermission(data){
    return request({
        url:Config.url203+'/query',
        method:'post',
        data:data
    })
}

//删除权限
export function deletePermission(data){
    return request({
        url:Config.url203+'/delete',
        method:'post',
        data:data
    })
}

export function getProduct(){
    return request({
        url:Config.product,
        method:'get',
    })
}
