<template>
  <el-pagination
    :small="ifsmall"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :page-size="pageSize"
    layout="total, prev, pager, next, jumper"
    :total="total"
    style="
      float: right;
      margin-bottom: 30px;
      margin-right: 40px;
      margin-top: 34px;
    "
  ></el-pagination>
</template>
<script type="text/ecmascript-6">
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 1,
    },
    ifsmall: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    //每页展示条数
    handleSizeChange(val) {
      //事件传递
      this.$emit("handleSizeChangeSub", val);
    },
    //当前页
    handleCurrentChange(val) {
      //事件传递
      this.$emit("handleCurrentChangeSub", val);
    },
  },
};
</script>
<style type="text/css" scoped>
</style>
